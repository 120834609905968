<template>
	<div class="MicroClass">
		<div class="row clearfix displayFlex" id="clearfix">
			<Nav_class></Nav_class>
			<div class="column" style="margin: 0 15px;flex: 1 1 auto;overflow: auto;">
				<div class="class_study_con displayFlex justifyContentSpaceBetween">
					<div class="class_study_con_base"> 笔记列表 </div>
					<div class="class_study_con_base">
						<span style="margin-right: 10px;" @click="init()">
							<a @click="">发起笔记</a>
						</span>
						<span style="margin-right: 10px;">
							<a @click="DownloadNotesShow=true;QueryUserListByClassId()">批量下载笔记</a>
						</span>
					</div>
				</div>
				<div>
					<BaseTable :tableData="queryTeachListData.list" :paginationData="queryTeachListData"
						:tableColumn="tableColumn1">
						<template v-slot:begin="{row}">
							<!-- <el-date-picker style="position: absolute;opacity: 0;" v-model="start_time"
								@change="updatebaseInfo(row,start_time,'beginTime')" type="datetime"
								:placeholder="$store.state.teachlanguagedata.select" format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"
								value-format="timestamp" /> -->
							<div>{{row.beginTime | formatDate }}</div>
						</template>
						<template v-slot:end="{row}">
							<!-- <el-date-picker style="position: absolute;opacity: 0;" v-model="end_time"
								@change="updatebaseInfo(row,end_time,'endTime')" type="datetime"
								:placeholder="$store.state.teachlanguagedata.select" format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"
								value-format="timestamp" /> -->
							<div>{{row.endTime | formatDate }}</div>
						</template>
						<template v-slot:caozhuo="{row}">
							<div>
								<el-button @click="ButtonMiddle(row,'1','Handwriting')">查看</el-button>
								<el-button @click="ButtonMiddle(row,'2')">下载</el-button>
							</div>
						</template>
					</BaseTable>
				</div>
			</div>
		</div>
		<!-- 发起笔记 -->
		<PublishJob ref="PublishJob" :CreateAiQuestionSetShow="CreateAiQuestionSetShow" :stageIndex="stageIndex"></PublishJob>
		<!-- 下载笔记 -->
		<el-dialog title="批量下载笔记" :visible.sync="DownloadNotesShow" width="30%">
			<div style="display: flex;align-items: center;justify-content: space-between;">
				<h4>请选择学生/教师</h4>
				<el-select clearable filterable v-model="studentId">
					<el-option v-for="item in studentArry.list" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
			<div style="display: flex;align-items: center;justify-content: space-between;">
				<h4>请选择时间</h4>
				<el-date-picker v-model="stratEndTime" type="datetimerange" value-format="timestamp" :picker-options="pickerOptionsTime"
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
				</el-date-picker>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addNoteShow=false">{{$store.state.teachlanguagedata.cancel}}</el-button>
				<el-button type="primary" @click="GotUserBatchSetPointsData()">
					{{$store.state.teachlanguagedata.confirm}}
				</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import Nav_class from '../../components/Nav_class'
	import BaseTable from '../../components/BasicComponents/BaseTable.vue'
	import CreateBook from '../../components/ComponentPage/CreateBook.vue'
	import PublishJob from '../../components/PublishJob/index.vue'
	import {
		getPageWorks,
		updateBaseInfo,
		gotUserBatchSetPointsData,
		queryUserListByClassId,
		downLoadPointPdf,
		tasksave
	} from '../../api/base.js'
	import {showLoading,hideLoading} from '../../utils/Loading.js'
	import {
		queryTeachList,
		roomPlanSave
	} from '../../api/notebook.js'
	export default {
		name: 'MicroClass',
		data() {
			return {
				CreateAiQuestionSetShow:3,
				stageIndex:1,
				planKind:'笔记',
				DownloadNotesShow: false,
				tableCheck: null,
				pickerOptions: {
					disabledDate(time) {
						return Date.now() - 8.64e7 >= time.getTime()
					}
				},
				start_time: '',
				end_time: '',
				addNoteShow: false, //发起笔记显示
				queryTeachListData: {
					currPage: 1,
					pageSize: 10,
					list: []
				},
				studentArry: {
					currPage: 1,
					pageSize: 1000,
					list: []
				},
				tableColumn1: [{
					prop: "name",
					label: "名称",
				}, {
					prop: "beginTime",
					label: "开始时间",
					slot: true,
					slotName: 'begin'
				}, {
					prop: "endTime",
					label: "结束时间",
					slot: true,
					slotName: 'end'
				}, {
					prop: "status",
					label: "状态",
				}, {
					prop: "id",
					label: "操作",
					slot: true,
					slotName: 'caozhuo'
				}],
				pickerOptionsTime: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				studentId:'',
				stratEndTime: []
			}
		},
		components: {
			Nav_class,
			BaseTable,
			CreateBook,
			PublishJob
		},
		watch: {
			addNoteShow(val) {
				if (val) {
					if (JSON.parse(localStorage.getItem('teachchapters')).length != 1) {
						this.$message.error('教学章节有且只能选中一个')
						this.addNoteShow = false
					}
				}
			}
		},
		filters: { //配置过滤器
			formatDate: function(value) { //调用时间戳为日期显示
				let date = new Date(value)
				let y = date.getFullYear() //获取年份
				let m = date.getMonth() + 1 //获取月份
				m = m < 10 ? "0" + m : m //月份不满10天显示前加0
				let d = date.getDate() //获取日期
				d = d < 10 ? "0" + d : d //日期不满10天显示前加0
				//也可以获取更精准时间
				let h = date.getHours() //小时
				h = h < 10 ? "0" + h : h //月份不满10天显示前加0
				let mi = date.getMinutes() //分
				mi = mi < 10 ? "0" + mi : mi //月份不满10天显示前加0
				let s = date.getSeconds() //秒
				s = s < 10 ? "0" + s : s //月份不满10天显示前加0
				return y + "-" + m + "-" + d + " " + h + ":" + mi + ":" + s
			},
		},
		create() {},
		mounted() {

		},
		methods: {
			//创建作业初始化
			init(){
				this.$refs.PublishJob.init()
			},
			QueryUserListByClassId() {
				let data = {
					currPage: this.studentArry.currPage,
					pageSize: this.studentArry.pageSize,
					fromId: JSON.parse(localStorage.getItem('teachschoolID')),
					id: JSON.parse(localStorage.getItem('teachclass_id')),
					relationKind: 'student'
				}
				queryUserListByClassId(data).then(res => {
					this.studentArry=res.data.result
					// 添加老师
					// let teachData={
					// 	id:JSON.parse(localStorage.getItem('teachlogin')).id,
					// 	name:JSON.parse(localStorage.getItem('teachlogin')).name,
					// }
					// this.studentArry.list.push(teachData)
				})
			},
			GotUserBatchSetPointsData() {
				this.DownloadNotesShow=false
				showLoading()
				let data = {
					userId: this.studentId,
					endTime: this.stratEndTime[1],
					beginTime: this.stratEndTime[0],
					userToken: JSON.parse(localStorage.getItem('teachuserToken')),
					subjectId: JSON.parse(localStorage.getItem('teachsubjectId'))
				}
				gotUserBatchSetPointsData(data).then(res => {
					hideLoading()
					if(res.data.result&&res.data.result.pdfVoList&&res.data.result.pdfVoList.length>0){
						this.DownLoadPointPdf(res.data.result)
					}else{
						this.$message.error('该时间范围没有笔记')
					}
				}).catch(res=>{
					hideLoading()
				})
			},
			DownLoadPointPdf(data) {
				showLoading()
				downLoadPointPdf(data).then(res => {
					hideLoading()
					this.addNoteShow=false
					let imgUrl;
					if(res.data.data.indexOf('http')==-1){
						imgUrl = "https://api.youcun.tech/epen-slice-upload/" + res.data.data
						this.DownloadProcessingPDF(imgUrl)
					}else{
						imgUrl =res.data.data
						this.DownloadProcessing(imgUrl)
					}
				}).catch(res=>{
					hideLoading()
				})
			},
			DownloadProcessing (data){
				let aLink = document.createElement('a');
				aLink.href = data;
				aLink.style.display = 'none';
				document.body.appendChild(aLink);
				aLink.click();
				document.body.removeChild(aLink)
			},
			DownloadProcessingPDF(data) {
				var ajax = new XMLHttpRequest();
				ajax.open("GET", data, true);
				// ajax.setRequestHeader("Cache-Control", "no-cache")
				// ajax.setRequestHeader('Access-Control-Allow-Origin', '*');
				ajax.responseType = 'blob';
				ajax.onload = e => {
					let res = e.target.response
					let blob = new Blob([res], {
						type: "application/pdf;charset=UTF-8"
					}) // 这里的res为后端返回的流数据
					let aLink = document.createElement("a")
					let name = new Date().getTime()
					aLink.download = name + '.pdf' // 下载文件的名字
					aLink.href = URL.createObjectURL(blob)
					aLink.click()
				}
				ajax.send()
			},
			updatebaseInfo(val1, val2, val3) {
				let data = {
					"id": val1.id,
					[val3]: val2
				}
				updateBaseInfo(data).then(res => {
					this.QueryTeachList()
				})
			},
			initialization(val) {
				this.queryTeachListData.currPage = 1
				this.queryTeachListData.pageSize = 10
				this.QueryTeachList()
			},
			addNoteFun() {
				let data = this.$refs.CreateBook.form
				data.planKind = '笔记'
				if (data.roomId) {
					this.RoomPlanSave(data)
				}else{
					this.Tasksave(data)
				}
			},
			Tasksave(data){
				data.beginTime=this.$refs.CreateBook.formTime[0]
				data.endTime=this.$refs.CreateBook.formTime[1]
				delete data.num
				delete data.roomId
				tasksave(data).then(res=>{
					this.addNoteShow=false
					this.QueryTeachList()
				})
			},
			RoomPlanSave(data) {
				roomPlanSave(data).then(res => {
					this.addNoteShow = false
					this.QueryTeachList()
				})
			},
			//微课列表
			QueryTeachList() {
				let data = {
					planKind: "笔记",
					"chapterIds": JSON.parse(localStorage.getItem("teachchapters")),
					"userId": JSON.parse(localStorage.getItem("teachuserID")),
					"classId": JSON.parse(localStorage.getItem("teachclass_id")),
					currPage: this.queryTeachListData.currPage,
					pageSize: this.queryTeachListData.pageSize,
					textbookId:JSON.parse(localStorage.getItem('textbookId')),
					subjectId:JSON.parse(localStorage.getItem('teachsubjectId')),
				}
				getPageWorks(data).then(res => {
					this.queryTeachListData = res.data.result
				})
			},
			ButtonMiddle(params, stutas, url) {
				this.tableCheck = params
				localStorage.setItem('teachNote', JSON.stringify(params))
				localStorage.setItem('teachQuestionSetInformation', JSON.stringify(params))
				localStorage.setItem('teachplan_id', JSON.stringify(params.id))
				let questionSetId=this.$Download.getQuestionSet(params.resourceRelationList,'questionSet')
				localStorage.setItem('teachsetId', JSON.stringify(questionSetId))
				if (stutas == '1') {
					this.go_to(url)
				} else if (stutas == '2') {
					this.$Download.downloads(questionSetId)
				}
			},
			go_to(url, params) {
				this.routerPush(url)
			},
			//跳转页面 跳转地址 参数
			routerPush(url) {
				this.$router.push({
					name: '' + url,
				});
			},
		}
	}
</script>

<style>

</style>
