<template>
	<div>
		<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20">
			<span class="whiteSpaceNowrap">名称</span>
			<el-input v-model="form.name" class="width220" />
		</div>
		<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20" v-if="CreateBookJiaos&&ClassQueryListData.list&&ClassQueryListData.list.length>0">
		<!-- <div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20"> -->
			<span>教室</span>
			<el-select clearable filterable v-model="form.roomId" placeholder="请选择教室" class="width220">
				<el-option v-for="item in ClassQueryListData.list"  :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select>
		</div>
		<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20" v-if="CreateBookTime&&form.roomId">
		<!-- <div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20"> -->
			<span class="whiteSpaceNowrap">时间</span>
			<el-select clearable filterable v-model="queryTimeListDataCheck" placeholder="请选择时间" class="width220">
				<el-option v-for="item in queryTimeListData" :key="item.id"
					:label="`${item.code}:${item.beginTime}~${item.endTime}`" :value="item.id">
				</el-option>
			</el-select>
		</div>
		<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20" v-if="(!CreateBookTime)||(!form.roomId)">
			<span class="whiteSpaceNowrap">时间</span>
			<el-date-picker v-model="formTime" type="datetimerange" value-format="timestamp" :picker-options="pickerOptions" range-separator="至"
				start-placeholder="开始日期" end-placeholder="结束日期" align="right">
			</el-date-picker>
		</div>
		<div style="text-align: left;">
			<el-radio-group v-model="classType">
				<el-radio-button label="教室">资源</el-radio-button>
				<el-radio-button label="班级">题集</el-radio-button>
			</el-radio-group>
		</div>
		<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20">
			<span>{{classType=='教室'?'资源':'题集'}}</span>
			<el-select clearable filterable v-model="resourceQueryListCheck" placeholder="请选择资源" class="width220">
				<el-option v-for="item in resourceQueryListData.list" :key="item.id" :label="item.name"
					:value="item.id">
				</el-option>
			</el-select>
		</div>
	</div>
</template>

<script>
	import {
		queryTimeList,
		classQueryList,
		resourceQueryList,
		questionSetQueryList
	} from '../../api/base.js'
	import {
		getjiaoxueliebiao
	} from '../../api/class.js'
	export default {
		name: 'CreateBook',
		data() {
			return {
				queryTimeListDataCheck: '',
				resourceQueryListCheck: '',
				ClassQueryListData: '',
				resourceQueryListData: '',
				queryTimeListData: '',
				classType: '教室',
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				formTime: [],
				form: {
					beginTime: '',
					endTime: '',
					name: '',
					num: '',
					roomId: '',

					planKind: '',
					stageKind: '课中',

					fromId: JSON.parse(localStorage.getItem('teachschoolID')),
					subjectId: JSON.parse(localStorage.getItem('textbooklist')).subjectId,
					subjectName: JSON.parse(localStorage.getItem('textbooklist')).subjectName,

					resourceRelationList: [{
						"relationId": '',
						"relationKind": '',
						"relationName": ''
					}, {
						"relationId": JSON.parse(localStorage.getItem('teachchapters'))[0],
						"relationKind": "chapter"
					}],
					userRelationList: [{
						relationId: JSON.parse(localStorage.getItem('teachclass_id')),
						relationKind: "class",
						relationName: ""
					}]
				}
			}
		},
		props: ['CreateBookJiaos', 'CreateBookTime'],
		watch: {
			queryTimeListDataCheck(val) {
				let data = this.PropertyReturnObject(this.queryTimeListData, 'id', val)
				let time = new Date().toLocaleDateString();
				this.form.num = data[0].number
				this.form.beginTime = new Date(time + ' ' + data[0].beginTime + ":00").getTime()
				this.form.endTime = new Date(time + ' ' + data[0].endTime + ":00").getTime()
			},
			classType(val) {
				this.resourceQueryListCheck = ''
				if (val == '班级') {
					this.QuestionSetQueryList()
					this.form.roomId = ''
				} else {
					this.ResourceQueryList()
				}
			},
			resourceQueryListCheck(val) {
				if (val) {
					this.form.resourceRelationList[0].relationId = val
					this.form.resourceRelationList[0].relationName = this.PropertyReturnObject(this.resourceQueryListData
						.list, 'id', val)[0].name
					this.form.resourceRelationList[0].relationKind = this.PropertyReturnObject(this.resourceQueryListData
						.list, 'id', val)[0].type
				}
			}
		},
		mounted() {
			let data = new Date().toLocaleDateString();
			this.form.userRelationList[0].relationName = this.PropertyReturnObject(JSON.parse(localStorage.getItem(
				'teachlogin')).classes, 'id', JSON.parse(localStorage.getItem('teachclass_id')))[0].name
			this.QueryTimeList()
			this.ClassQueryList()
			this.ResourceQueryList()
		},
		methods: {
			ResourceQueryList() {
				let data = {
					"currPage": 1,
					"pageSize": 10000,
					"corpId": JSON.parse(localStorage.getItem('teachschoolID')),
				}
				resourceQueryList(data).then(res => {
					this.resourceQueryListData = res.data.result
				})
			},
			QuestionSetQueryList() {
				let data = {
					"currPage": 1,
					"pageSize": 10000,
					"corpId": JSON.parse(localStorage.getItem('teachschoolID')),
					relationId: JSON.parse(localStorage.getItem('teachchapters'))[0],
					relationKind: "chapter",
				}
				questionSetQueryList(data).then(res => {
					this.resourceQueryListData = res.data.result
				})
			},
			ClassQueryList() {
				let data = {
					"pageSize": 1000,
					"currPage": 1,
					"fromId": JSON.parse(localStorage.getItem('teachschoolID')),
					"classKind": "room"
				}
				classQueryList(data).then(res => {
					this.ClassQueryListData = res.data.result
				})
			},
			QueryTimeList() {
				queryTimeList({
					"fromId": JSON.parse(localStorage.getItem('teachschoolID'))
				}).then(res => {
					this.queryTimeListData = res.data.result
				})
			},
			//根据对象一个属性返回数组中一个对象
			PropertyReturnObject(arry, attribute, value) {
				let canshu = arry.filter((item, index) => {
					return item[attribute] == value
				})
				return canshu
			},
		},
	}
</script>

<style>
</style>
